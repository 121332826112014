
.single-shopping-support {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .icon {
         color: #dcb14a;
         font-size: 48px;
         margin-right: 26px;
    }
}



